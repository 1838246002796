import { Fragment } from 'react';
import Head from 'next/head';

import type { NextPageWithLayout } from '@/model/types';

import DeviceLayout from '@/layouts/DeviceLayout';
import NotFound from '@/components/Error/NotFound';

/**
 * @see https://nextjs.org/docs/advanced-features/custom-error-page
 * @function Page Custom 404 page
 */
const Page: NextPageWithLayout = () => {
  return (
    <Fragment>
      <Head>
        <title key="title">404 - Page Not Found</title>
      </Head>
      <NotFound />
    </Fragment>
  );
};

Page.getLayout = page => {
  return (
    <DeviceLayout withDesktopTopNavigation withMobileTopNavigation>
      {page}
    </DeviceLayout>
  );
};

export default Page;
